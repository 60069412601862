// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fondue-alpeggi-js": () => import("./../../../src/pages/fondue/alpeggi.js" /* webpackChunkName: "component---src-pages-fondue-alpeggi-js" */),
  "component---src-pages-fondue-beeehstiale-js": () => import("./../../../src/pages/fondue/beeehstiale.js" /* webpackChunkName: "component---src-pages-fondue-beeehstiale-js" */),
  "component---src-pages-fondue-cantoni-js": () => import("./../../../src/pages/fondue/cantoni.js" /* webpackChunkName: "component---src-pages-fondue-cantoni-js" */),
  "component---src-pages-fondue-classica-js": () => import("./../../../src/pages/fondue/classica.js" /* webpackChunkName: "component---src-pages-fondue-classica-js" */),
  "component---src-pages-fondue-gustosa-js": () => import("./../../../src/pages/fondue/gustosa.js" /* webpackChunkName: "component---src-pages-fondue-gustosa-js" */),
  "component---src-pages-fondue-index-js": () => import("./../../../src/pages/fondue/index.js" /* webpackChunkName: "component---src-pages-fondue-index-js" */),
  "component---src-pages-fondue-ticinese-js": () => import("./../../../src/pages/fondue/ticinese.js" /* webpackChunkName: "component---src-pages-fondue-ticinese-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

